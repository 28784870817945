<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 ml-5 mt-5">
      <h1>
        Usuario Maestro de
        <span class="font-weight-light">{{
          selectedCorporative.shortName
        }}</span>
      </h1>
    </v-row>

    <v-row style="margin-left: 40px; margin-right: 40px" class="mt-3">
      <v-col cols="12" sm="10" md="10">
        <input
          class="control-input control-input-number"
          placeholder="Número de cel en Ocho Ejem : +50497662551"
          v-model="phone"
          v-on:keyup.enter="searchUser"
        />
      </v-col>

      <v-col cols="12" sm="2" md="2">
        <v-btn
          @click="searchUser"
          class="save-btn mt-2"
          block
          height="45"
          :loading="loading"
          color="primary"
          dark
        >
          Buscar usuario
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="currentUser"
      class="mt-10"
      style="margin-left: 40px; margin-right: 40px"
    >
      <v-col class="user-data" cols="12" sm="12" md="12">
        <h3>
          <b>Información del usuario actual</b>
        </h3>
        <p class="mt-5">
          Nombre : {{ currentUser.name }} {{ currentUser.surname }}
        </p>
        <p>Teléfono : {{ currentUser.phone }}</p>
        <p>Email : {{ currentUser.email }}</p>
      </v-col>
    </v-row>

    <v-row
      v-if="currentUser"
      class="mt-10"
      style="margin-left: 40px; margin-right: 40px"
    >
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="getCards"
          :items-per-page="5"
        >
          <template v-slot:[`item.color`]="{ item }">
            <div
              :style="`background-color:${item.color}; width:30px;height:30px;border-radius:30px`"
            ></div>
          </template>
          <template v-slot:[`item.validThru`]="{ item }">
            {{ item.validThru | filterDate }}
          </template>

          <template v-slot:[`item.isPrincipal`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isPrincipal: item.isPrincipal },
                  item['.key'],
                  item,
                  'isPrincipal'
                )
              "
              v-model="item.isPrincipal"
            ></v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog max-width="600" v-model="resultDialog" v-if="resultDialog">
      <user-details
        @cancel="resultDialog = false"
        :user="userResult"
        :loading="loading"
        @associate="associate"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import userDetails from "./user-details";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "corporative-user",
  components: {
    userDetails,
  },

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      phone: "",
      userResult: null,
      userCards: [],
      resultDialog: false,
      currentUser: null,
      business: null,
      headers: [
        { text: "Marca", value: "brand" },
        { text: "Termina en", value: "endsIn" },
        { text: "Color", value: "color" },
        { text: "Nombre", value: "name" },
        { text: "Vence", value: "validThru" },
        { text: "Principal", value: "isPrincipal" },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YY");
    },
  },
  watch: {
    selectedCorporative() {
      this.business = null;
      this.getBusinessData();
    },

    business() {
      if (this.business.selectedCard) {
        this.userCards.forEach((item) => {
          item.isPrincipal =
            item[".key"] == this.business.selectedCard ? true : false;
        });
      }
    },
  },
  computed: {
    ...mapState(["selectedCorporative"]),

    getCards() {
      return this.userCards.map((e) => {
        let card = e;

        if (card[".key"] == this.business.selectedCard) {
          card.isPrincipal = true;
        }

        return card;
      });
    },
  },
  methods: {
    associate() {
      this.loading = true;
      db.collection("corporateCoupons")
        .doc(this.selectedCorporative[".key"])
        .update({
          ochoUserId: this.userResult.id,
          selectedCard: "",
        })
        .then((resp) => {
          this.snackbarText = "Usuario maestro actualizado exitosamente.";
          this.snackbar = true;
          this.loading = false;
          this.resultDialog = false;
          this.phone = "";
          this.getBusinessData();
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
    async searchUser() {
      if (this.phone) {
        let result = await db
          .collection("users")
          .where("phone", "==", this.phone)
          .get();

        if (result.size) {
          let user = result.docs[0].data();
          user.id = result.docs[0].id;
          this.userResult = user;
          this.resultDialog = true;
        }
      }
    },
    async getBusinessData() {
      if (!this.selectedCorporative) {
        this.$router.push({ path: "/" });
      }

      await this.$binding(
        "business",
        db
          .collection("corporateCoupons")
          .doc(this.selectedCorporative[".key"])
      );

      if (this.business.ochoUserId) {
        this.$binding(
          "currentUser",
          db.collection("users").doc(this.business.ochoUserId)
        );

        await this.$binding(
          "userCards",
          db
            .collection("users")
            .doc(this.business.ochoUserId)
            .collection("cards")
            .where("deleted", "==", false)
        );
      }

      this.loading = false;
    },
    switchControlChanged(data, docId, card, field) {
      this.loading = true;
      db.collection("corporateCoupons")
        .doc(this.selectedCorporative[".key"])
        .update({
          selectedCard: data.isPrincipal ? docId : "",
        })
        .then((res) => {
          this.snackbarText = "Tarjeta principal actualizada exitosamente.";
          this.snackbar = true;
          this.loading = false;
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
          card.isPrincipal = !card.isPrincipal;
        });
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
  },
};
</script>

<style scoped>
.user-data {
  background-color: rgba(141, 141, 141, 0.308);
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
}
</style>